<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs"></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'shipserviceap-grid',
	components: {},
	data() {
		return {
			modelName: 'shipserviceap',
			modelColumnDefs: {
				noanchorageberth: {
					render: (data, type, row) => {
						return row.noanchorageberth ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				noevents: {
					render: (data, type, row) => {
						return row.noevents ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	}
};
</script>
